import React from 'react'
import Layout from '../../components/resuable/layout/layout';
import { graphql } from 'gatsby'

const TagTemplate = () => {
  return (
    <Layout>
      <h1>Tag Template</h1>
    </Layout>
  )
}

export default TagTemplate

